import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Container, Button, Spinner, Alert, Form } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const { users, loggedInUser, setUsers, loading, setLoading, isAuthenticated, logout } = useContext(BankContext);
  const alertShown = useRef(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(20);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      fetchTransactions();
    }
  }, [isAuthenticated, navigate]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://mark-parinasfullstackbankingapplication.app/api/user/transactions");
      setUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
      if (error.response && error.response.status === 401) {
        logout();
        navigate('/login');
      } else {
        setError("Failed to load transactions. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const loggedInUserData = users?.find((user) => user._id === loggedInUser?._id);

 // Function to consolidate and remove duplicate transfer entries
const consolidateTransfers = (transactions) => {
  const consolidatedTransactions = {};
  transactions.forEach(entry => {
    const key = `${entry.amount}-${entry.createdAt}`;
    if (entry.type === "receive" || entry.type === "transfer_deposit") {
      if (!consolidatedTransactions[key] || (consolidatedTransactions[key].type === "transfer_deposit" && entry.type === "receive")) {
        consolidatedTransactions[key] = {
          ...entry,
          type: "receive",
          senderOrReceiverEmail: entry.senderOrReceiverEmail || consolidatedTransactions[key]?.senderOrReceiverEmail
        };
      }
    } else {
      consolidatedTransactions[key] = entry;
    }
  });
  return Object.values(consolidatedTransactions);
};

// Sort transactions in reverse chronological order
const sortedTransactions = loggedInUserData?.transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
const consolidatedTransactions = consolidateTransfers(sortedTransactions || []);

// Get current transactions for pagination
const indexOfLastTransaction = currentPage * transactionsPerPage;
const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
const currentTransactions = consolidatedTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to format date and time
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "N/A";
    
    const date = new Date(dateTimeString);
    
    if (isNaN(date.getTime())) {
      console.log("Failed to parse date:", dateTimeString);
      return "Invalid Date";
    }

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };


  return (
    <Container fluid>
      <Row>
        <Col xs={12} lg={9} className="order-lg-1">
          <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
            <Card.Header>MIT University Bank & Shop Account Summary</Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" />
              ) : error ? (
                <Alert variant="danger">{error}</Alert>
              ) : (
                <>
                <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={loggedInUser ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : ''}
                      disabled
                      style={{ textAlign: "center", marginBottom: '20px' }}
                    />
                  </Form.Group>
                  <Card className="text-left" style={{ marginTop: '20px', marginRight: '20px' }}>
                    <Card.Text as="div">
                      <h1 style={{ textAlign: 'left', marginLeft: '30px' }}>
                        Total Account Balance: {"$" + (loggedInUser?.balance + loggedInUser?.transferBalance)}
                      </h1>
                    </Card.Text>
                  </Card>
                  <Card className="text-left" style={{ marginTop: '20px', marginRight: '20px' }}>
                    <Card.Text as="div">
                      <h1 style={{ textAlign: 'left', marginLeft: '30px' }}>
                        Earned Balance: {"$" + loggedInUser?.balance}
                      </h1>
                    </Card.Text>
                  </Card>
                  <Card className="text-left" style={{ marginTop: '20px', marginRight: '20px' }}>
                    <Card.Text as="div">
                      <h1 style={{ textAlign: 'left', marginLeft: '30px' }}>
                        MITpoints Balance: {loggedInUser?.transferBalance + " point(s)"}
                      </h1>
                    </Card.Text>
                  </Card>
                </>
              )}

              {loggedInUserData && !loading && (
                <Card.Text as="div">
                  <Row
                    style={{
                      fontWeight: "bold",
                      borderBottom: "1px solid black",
                      marginTop: "20px",
                    }}
                  >
                    <Col style={{ flex: 1 }}>First Name</Col>
                    <Col style={{ flex: 1 }}>Last Name</Col>
                    <Col style={{ flex: 2 }}>Email</Col>
                    <Col style={{ flex: 1 }}>Password</Col>
                    <Col style={{ flex: 1 }}>Account Balance</Col>
                  </Row>
                  <Row style={{ paddingTop: "20px" }}>
                    <Col style={{ flex: 1 }}>{loggedInUserData.firstName}</Col>
                    <Col style={{ flex: 1 }}>{loggedInUserData.lastName}</Col>
                    <Col style={{ flex: 2 }}>{loggedInUserData.email}</Col>
                    <Col style={{ flex: 1 }}>********</Col>
                    <Col style={{ flex: 1 }}>{loggedInUserData.balance + loggedInUserData.transferBalance} point(s)</Col>
                  </Row>

                  {currentTransactions?.length > 0 && (
                    <>
                      <Row
                        style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid black",
                          marginTop: "20px",
                        }}
                      >
                        <Col style={{ flex: 4 }}>Date & Time</Col>
                        <Col style={{ flex: 6 }}>Type</Col>
                        <Col style={{ flex: 2 }}>Amount</Col>
                        <Col style={{ flex: 3 }}>Running Balance</Col>
                      </Row>
                      {currentTransactions.map((entry, index) => (
                          <Row key={index}>
                            <Col style={{ flex: 4 }} className="left-align">
                              {formatDateTime(entry.createdAt)}
                            </Col>
                            <Col style={{ flex: 6 }} className="left-align">
                              {entry.type === "send" ? (
                                <span>Send (to {entry.senderOrReceiverEmail || 'Unknown'})</span>
                              ) : entry.type === "receive" || entry.type === "transfer_deposit" ? (
                                entry.senderOrReceiverEmail ? 
                                  <span>Receive (from {entry.senderOrReceiverEmail})</span> : 
                                  <span>Receipt Confirmed</span>
                              ) : (
                                <span style={{ textTransform: "capitalize" }}>{entry.type}</span>
                              )}
                            </Col>
                            <Col style={{ flex: 2 }} className="left-align">
                              {entry.amount} point(s)
                            </Col>
                            <Col style={{ flex: 3 }} className="left-align">
                              {entry.runningBalance} point(s)
                            </Col>
                          </Row>
                        ))}
                        <Pagination
                        transactionsPerPage={transactionsPerPage}
                        totalTransactions={sortedTransactions.length}
                        paginate={paginate}
                      />
                    </>
                  )}
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} lg={3} className="order-lg-2">
          <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
            <Card.Header>Welcome to your MIT University Bank & Shop Account</Card.Header>
            <Card.Body>
              <Card.Title style={{ marginBottom: '10px' }}>
                {!loggedInUser ? (
                  'Please Login to view your account information.'
                ) : (
                  <div style={{ textAlign: 'left', fontSize: '14px'}}>
                    You are currently logged in to your MIT University Bank & Shop. 
                    <br/><br/>
                    You may execute transactions in your account using the tabs in the navigation menu.
                  </div>
                )}
              </Card.Title>
            </Card.Body>
          </Card>

          <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
            <Card.Header>MIT Bank & Shop Shortcuts</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                {loggedInUser ? (
                  <ul style={{ textAlign: 'left', fontSize: '14px', listStyleType:'circle'}}>
                    <li>
                      <NavLink to="/home">Home</NavLink> - Access your home page by selecting the MIT logo.
                    </li>
                    <br/>
                    <li>
                      <NavLink to="/deposit">Deposit</NavLink> - Add funds into your account and see the increase in your balance.
                    </li>
                    <br/>
                    <li>
                      <NavLink to="/withdraw">Withdraw</NavLink> - Subtract funds from your account and see the decrease in your balance.
                    </li>
                    <br/>
                    <li>
                      <NavLink to="/transfer">Transfer</NavLink> - Gift funds from your balance to another user and they will see the points added to their account and subtracted from your funds.
                    </li>
                    <br/>
                    <li>
                      <NavLink to="/purchased-items">Purchased</NavLink> - See items from the giftshop that have already purchased.
                    </li>
                    <br/>
                    <li>
                      <NavLink to="/cart">My Cart</NavLink> - See items in your cart ready to be purchased to complete the transaction.
                    </li>
                    <br/>
                    <li>
                      <NavLink to="/logout">Logout</NavLink> - Close access and secure your account after use. 
                    </li>
                  </ul>
                ) : (
                  'Please login to see account-related information.'
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Pagination = ({ transactionsPerPage, totalTransactions, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalTransactions / transactionsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <Button onClick={() => paginate(number)} className="page-link">
              {number}
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Home;
