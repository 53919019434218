import React, { useContext, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { BankContext, BankProvider } from "./context/BankContext";
import NavigationBar from "./components/Navbar";
import Home from "./components/Home";
import Login from "./components/Login";
import './App.css';

// Lazy load components
const CreateAccount = lazy(() => import("./components/CreateAccount"));
const Deposit = lazy(() => import("./components/Deposit"));
const Withdraw = lazy(() => import("./components/Withdraw"));
const AllData = lazy(() => import("./components/AllData"));
const Transfer = lazy(() => import("./components/Transfer"));
const GiftShop = lazy(() => import("./components/GiftShop"));
const Cart = lazy(() => import("./components/Cart"));
const PurchasedItems = lazy(() => import("./components/PurchasedItems"));
const Inventory = lazy(() => import("./components/Inventory"));
const Receipt = lazy(() => import("./components/Receipt"));

// Protected route component to ensure user access
const ProtectedRoute = ({ children, isAdmin = false }) => {
  const { loggedInUser } = useContext(BankContext);

  if (!loggedInUser) {
    return <Navigate to="/login" replace />;
  }

  if (isAdmin && loggedInUser.email !== "admin@gmail.com") {
    return <Navigate to="/home" replace />;
  }

  return children;
};

function App() {
  return (
    <BankProvider>
      <Router>
        <NavigationBar />
        <div className="container mt-4">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/login" element={<Login />} />
              <Route path="/deposit" element={<ProtectedRoute><Deposit /></ProtectedRoute>} />
              <Route path="/withdraw" element={<ProtectedRoute><Withdraw /></ProtectedRoute>} />
              <Route path="/transfer" element={<ProtectedRoute><Transfer /></ProtectedRoute>} />
              <Route path="/all-data" element={<ProtectedRoute isAdmin={true}><AllData /></ProtectedRoute>} />
              <Route path="/gift-shop" element={<ProtectedRoute><GiftShop /></ProtectedRoute>} />
              <Route path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
              <Route path="/purchased-items" element={<ProtectedRoute><PurchasedItems /></ProtectedRoute>} />
              <Route path="/inventory" element={<ProtectedRoute isAdmin={true}><Inventory /></ProtectedRoute>} />
              <Route path="/receipt" element={<ProtectedRoute><Receipt /></ProtectedRoute>} />
              <Route path="/" element={<Navigate replace to="/home" />} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </BankProvider>
  );
}

export default App;