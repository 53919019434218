import image from "../assets/images/mitphoto1.jpg";
import React, { useContext, useEffect, useState } from "react";
import { Card, Form, Button, Alert, Modal, Spinner, Container, Col, Row } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { login, logout, loading, loggedInUser, isAuthenticated } = useContext(BankContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!email) {
      setError("Email is required");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      return;
    }

    try {
      const success = await login(email, password);
      if (success) {
        setShowModal(true);
      } else {
        setError("Invalid credentials or inactive account");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("An error occurred during login. Please try again.");
    }
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      setError("An error occurred during logout. Please try again.");
    }
  };

  const handleConfirm = () => {
    setShowModal(false);
    navigate("/home");
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} lg={3} className="order-lg-2">
            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>{isAuthenticated ? "You are currently logged in." : "Login"}</Card.Header>
              <Card.Body>
                {!isAuthenticated ? (
                  <>
                    <Card.Title>Login</Card.Title>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        style={{ marginTop: "30px", background: "red", border: "none" }}
                        variant="primary"
                        type="submit"
                        disabled={!email || !password || loading}
                      >
                        {loading ? 'Logging in...' : 'Login'}
                      </Button>
                    </Form>
                    {error && <Alert variant="danger">{error}</Alert>}
                  </>
                ) : (
                  <>
                    <p>Please remember to logout when you are not using your account.</p>
                    <Button
                      style={{ marginTop: "10px", background: "red", border: "none" }}
                      variant="primary"
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={9} className="order-lg-1">
            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Body>
                <Card.Header>Welcome to MIT University Bank & Shop</Card.Header>
              </Card.Body>
              <Card.Body>
                <img src={image} alt="MIT Bank & Shop" style={{ width: '80%', height: 'auto' }} />
              </Card.Body>
            </Card>
            <Card className="custom-card">
              <Card.Header>Instructions for first time Users</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <ul className="custom-text-left" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    <li>1. Create an account.</li>
                    <li>2. After creating an accunt, you'll have to wait.</li>
                    <li>3. System admin will need to activate your account.</li>
                    <li>4. System admin will have to give you privileges.</li>
                    <li>5. System admin may limit your user privilege.</li>
                    <li>6. Follow up with your system admin for your access.</li>
                    <li>7. When your account is activated, you'll see what your privileges are.</li>
                    <li>8. If you need access to more features, contact your system admin.</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Successfully Logged In</Modal.Title>
        </Modal.Header>
        <Modal.Body>Successfully logged in to your MIT University Bank & Shop</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirm}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;