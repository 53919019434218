import React, { createContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import setAuthToken from '../utils/setAuthToken';
import { jwtDecode } from "jwt-decode";

// Setting up axios interceptor outside of the component
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const res = await axios.post('https://mark-parinasfullstackbankingapplication.app/api/auth/refresh-token');
        localStorage.setItem('token', res.data.token);
        setAuthToken(res.data.token);
        return axios(originalRequest);
      } catch (err) {
        console.error("Error refreshing token:", err);
        // Implement logout logic here
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const BankContext = createContext();

export const BankProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          logout();
        } else {
          setAuthToken(token);
          loadUser();
        }
      } catch (error) {
        console.error("Invalid token:", error);
        logout();
      }
    }
  }, []);

  const loadUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');
  
      const decodedToken = jwtDecode(token);
      if (!decodedToken.user || !decodedToken.user.id) throw new Error('Invalid token');
  
      const res = await axios.get(`https://mark-parinasfullstackbankingapplication.app/api/user/${decodedToken.user.id}`);
      setLoggedInUser(res.data);
      setIsAuthenticated(true);
    } catch (err) {
      console.error(err);
      setLoggedInUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('token');
    }
  };

  const login = useCallback(async (email, password) => {
    try {
      const res = await axios.post('https://mark-parinasfullstackbankingapplication.app/api/auth/login', { email, password });
      localStorage.setItem('token', res.data.token);
      setAuthToken(res.data.token);
      setLoggedInUser(res.data.user);
      setIsAuthenticated(true);
      return true;
    } catch (err) {
      console.error("Login error:", err.response?.data || err.message);
      return false;
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setLoggedInUser(null);
    setIsAuthenticated(false);
  }, []);

  const refreshToken = async () => {
    try {
      const res = await axios.post('https://mark-parinasfullstackbankingapplication.app/api/auth/refresh-token');
      localStorage.setItem('token', res.data.token);
      setAuthToken(res.data.token);
      const userData = await axios.get(`https://mark-parinasfullstackbankingapplication.app/api/user/${res.data.userId}`);
      setLoggedInUser(userData.data);
      setIsAuthenticated(true);
      return true;
    } catch (err) {
      console.error("Error refreshing token:", err);
      logout();
      return false;
    }
  };

  // Axios interceptor to handle token expiration
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshed = await refreshToken();
        if (refreshed) {
          return axios(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );


  // Update user balance (for transfers, deposits, withdrawals)
  const updateBalance = async (amount, type, receiverEmail, isSalary, message, items = []) => {
    const timestamp = new Date();
    setLoading(true);
    
    const data = {
      userId: loggedInUser._id,
      type,
      amount,
      time: timestamp,
      receiverEmail,
      isSalary,
      isBuy: type === "purchase" ? "true" : "false",
      message,
      transactionType: type,
      relatedItemId: type === "purchase" ? items.map(item => item.giftItemId._id) : []
    };
  
    console.log("Data being sent to update-balance:", data);
  
    try {
      const response = await axios.put("https://mark-parinasfullstackbankingapplication.app/api/user/update-balance", data, {
        headers: { "Content-Type": "application/json" }
      });

      console.log("Response from update-balance:", response.data);
      
      await refreshLoggedInUser();
    setLoading(false);
    return response.data;
  } catch (error) {
    console.error("Error updating balance:", error.response?.data || error.message);
    console.error("Full error object:", error);
    setLoading(false);
    throw error;
  }
};

  const getPendingTransfers = async () => {
    if (!loggedInUser) return [];
    try {
      const response = await axios.get(`https://mark-parinasfullstackbankingapplication.app/api/user/${loggedInUser._id}/pending-transfers`);
      return response.data;
    } catch (error) {
      console.error("Error fetching pending transfers:", error);
      return [];
    }
  };


  // Add new user (for registration)
  const addUser = async (user) => {
    setLoading(true);
    
    const data = JSON.stringify({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      password: user?.password,
    });
  
    try {
      const response = await axios.post("https://mark-parinasfullstackbankingapplication.app/api/auth/register", data, {
        headers: { "Content-Type": "application/json" }
      });
      setLoading(false);
      return { success: true, data: response.data };
    } catch (error) {
      setLoading(false);
      if (error.response) {
        return { success: false, error: error.response.data.message || "Registration failed" };
      } else if (error.request) {
        return { success: false, error: "No response from server" };
      } else {
        return { success: false, error: "Error setting up the request" };
      }
    }
  };

  // Update specific fields for a user
  const updateFields = async (id, field) => {
    const data = JSON.stringify({ field });

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `https://mark-parinasfullstackbankingapplication.app/api/user/update/${id}`, // DAL route
      headers: { "Content-Type": "application/json" },
      data
    };

    try {
      await axios.request(config);
      await fetchUsers(); // Refresh users after update
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch all users and set state
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://mark-parinasfullstackbankingapplication.app/api/user/transactions"); // DAL route
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Refresh the logged-in user's data
  const refreshLoggedInUser = async () => {
    if (!loggedInUser) return;
    try {
      const response = await axios.get(`https://mark-parinasfullstackbankingapplication.app/api/user/${loggedInUser._id}`); // DAL route
      setLoggedInUser(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Monitor logged-in user status and update user states accordingly
  useEffect(() => {
    if (loggedInUser) {
      setUsers(prevUsers => prevUsers.map((u) => ({
        ...u,
        isLogin: u?._id === loggedInUser._id,
      })));
    }
  }, [loggedInUser]);

  return (
    <BankContext.Provider
      value={{
        updateBalance,
        users,
        addUser,
        loggedInUser,
        setLoggedInUser,
        getPendingTransfers,
        loading,
        isAuthenticated,
        login,
        logout,
        setLoading,
        setUsers,
        updateFields,
      }}
    >
      {children}
    </BankContext.Provider>
  );
};