import React, { useContext } from "react";
import { Navbar, Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { BankContext } from "../context/BankContext";
import logo from "../assets/images/MITlogo.jpg";

const NavigationBar = () => {
  const { loggedInUser, setLoggedInUser, setLoading, isAuthenticated, logout } = useContext(BankContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      alert("An error occurred while logging out.");
    }
  };

  return (
    <Navbar expand="lg" className="navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="home-tooltip">Go to Home Page</Tooltip>}
          >
            <Navbar.Brand as={NavLink} to="/home" className="navbar-brand">
              <img
                src={logo}
                width="70" 
                height="60" 
                className="d-inline-block align-top"
                alt="Logo"
              />
              <span className="navbar-brand-text">University Bank & Shop</span>
            </Navbar.Brand>
          </OverlayTrigger>
        </Nav>

        <Nav className="ms-auto">
          {!isAuthenticated ? (
            <>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="create-account-tooltip">Create a New Account</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/create-account">
                  Create Account
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="login-tooltip">Login to Account</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/login">
                  Login
                </Nav.Link>
              </OverlayTrigger>
            </>
          ) : (
            <>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="deposit-tooltip">Deposit Points</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/deposit">
                  Deposit
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="withdraw-tooltip">Withdraw Points</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/withdraw">
                  Withdraw
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="transfer-tooltip">Transfer Points</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/transfer">
                  Transfer
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="receipt-tooltip">Receipt</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/receipt">
                  Receipt
                </Nav.Link>
              </OverlayTrigger>

              {loggedInUser?.email === "admin@gmail.com" && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="all-data-tooltip">View All Data</Tooltip>}
                >
                  <Nav.Link as={NavLink} to="/all-data">
                    All Data
                  </Nav.Link>
                </OverlayTrigger>
              )}

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="gift-shop-tooltip">Gift Shop</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/gift-shop">
                  Gift Shop
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="cart-tooltip">My Cart</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/cart">
                  Cart
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="purchased-items-tooltip">Purchased Items</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/purchased-items">
                  Purchased
                </Nav.Link>
              </OverlayTrigger>

              {loggedInUser?.email === "admin@gmail.com" && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="inventory-tooltip">GiftShop Inventory</Tooltip>}
                >
                  <Nav.Link as={NavLink} to="/inventory">
                    Inventory
                  </Nav.Link>
                </OverlayTrigger>
              )}

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="logout-tooltip">Logout</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/login" onClick={handleLogout}>
                  Logout
                </Nav.Link>
              </OverlayTrigger>
            </>
          )}
        </Nav>
          <Nav className="ms-auto">
                {isAuthenticated && (
                  <div className="user-name-display">
                    User: {loggedInUser ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : ''}
                </div>
              )}
          </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
